import { makeStyles } from "@material-ui/core";
import { anime } from "../libs/utils";
import { Howl } from "howler";
import clsx from "clsx";

const useStyles = makeStyles({
    svg: {
        filter: "drop-shadow(0.33vh 0.66vh 0.8vh black)",
        height: "10vh",
        outline: "none"
    }
});

const CIAvatar = (props) => {
    const cls = useStyles();

    const handleClick = () => {
        new Howl({
            src: require("../assets/sounds/Click.mp3"),
            autoplay: true
        });
        anime({
            targets: "#" + props.id,
            scale: [0.9, 1],
            duration: 1000
        });
        props.onClick && props.onClick();
    }

    return <svg className={clsx(cls.svg, props.className, "pointer")} id={props.id} onClick={handleClick} viewBox="0 0 100 111" data-tooltip-id="tooltip"
        data-tooltip-content="Change Avatar">
        <path className="pointer" opacity="0.3" d="M99.9987 58.4278C100.194 86.8865 77.9737 110.114 50.3609 110.307C22.7445 110.5 0.198186 87.5869 0.00134468 59.1265C-0.199044 30.6678 22.0228 7.44044 49.6356 7.24715C77.2502 7.05208 99.7966 29.9673 99.9987 58.4278Z" fill="black" />
        <path className="pointer" d="M99.9987 51.1801C100.194 79.6388 77.9737 102.864 50.3609 103.059C22.7445 103.253 0.198186 80.341 0.00134468 51.8806C-0.199044 23.4219 22.0228 0.192758 49.6356 0.00123622C77.2502 -0.193832 99.7966 22.7214 99.9987 51.1801Z" fill="#552800" />
        <path className="pointer" d="M9.66964 26.1049C8.11973 30.8061 22.5867 22.693 48.5982 23.5495C74.6132 24.4025 83.7123 22.8633 85.1451 19.6925C86.5798 16.5218 72.1323 4.76092 57.8125 2.33675C43.4945 -0.0856463 16.646 4.9081 9.66964 26.1049Z" fill="#A85C09" />
        <path className="pointer" d="M10.9065 25.3725C7.36864 26.3053 5.19629 36.2946 5.25304 44.0512C6.37025 46.2058 40.9169 44.7428 41.1793 46.3371C41.4418 47.9313 7.91306 46.8567 6.65576 49.5734C5.39845 52.292 7.66834 61.5045 12.0077 68.4915C13.7279 71.2579 79.7106 77.7608 84.7115 73.0987C95.1689 63.3506 95.6832 40.0683 93.7786 36.6705C91.874 33.2746 73.1227 34.0495 73.1227 34.0495C73.1227 34.0495 89.7691 34.4113 90.7941 32.2052C92.0496 29.5009 88.0347 26.3532 83.9649 25.4754C79.8933 24.5976 21.0288 22.7054 10.9065 25.3725Z" fill="#A85C09" />
        <path className="pointer" d="M12.2335 66.9558C10.093 69.9492 22.6182 90.1565 46.9858 90.5076C68.4664 90.818 76.3755 83.0259 75.8559 82.4496C75.3328 81.8714 41.0557 82.3219 41.0557 82.3219C41.0557 82.3219 76.0988 80.3659 78.9309 79.2753C81.7647 78.1829 87.4075 70.6834 85.0631 69.0962C82.7241 67.5162 15.0495 63.019 12.2335 66.9558Z" fill="#A85C09" />
        <path className="pointer" d="M9.55647 52.1182C8.05799 53.7745 9.73203 60.421 9.55647 60.664C9.38091 60.9104 6.26159 53.0315 7.6714 50.054C8.29916 48.724 30.6629 47.6955 33.0888 48.2948C21.977 50.1515 10.9734 50.5541 9.55647 52.1182Z" fill="#B76400" />
        <path className="pointer" d="M45.412 3.40962C26.1055 1.57775 12.2344 17.6922 9.55664 28.8625C14.5699 20.4373 26.6287 6.33919 45.412 3.40962Z" fill="#B76400" />
        <path className="pointer" d="M76.7722 72.0524C81.1612 66.2004 79.3914 52.5527 69.278 44.4609C69.0032 43.6309 75.2613 34.8529 68.6875 24.9079C72.6492 28.8696 74.5023 32.4092 73.1546 39.1515C72.7112 40.3059 71.2163 43.028 72.3956 44.4609C76.2278 48.2931 78.6945 52.5101 79.9642 59.2187C80.8952 64.1397 81.3935 65.9503 78.9002 69.7914L76.7722 72.0524Z" fill="#B76400" />
        <path className="pointer" d="M28.0606 44.5389C27.1668 46.2112 10.625 43.7551 10.625 43.7551C14.9644 43.4944 24.732 42.5864 25.6116 41.0596C26.5763 38.8695 24.1078 25.8584 32.4602 18.9016C40.8127 11.9465 52.5044 8.18523 63.0151 14.4522C53.2421 10.2015 37.6526 13.6862 32.0063 22.3791C26.3582 31.0738 28.8267 43.1042 28.0606 44.5389Z" fill="#B76400" />
        <path className="pointer" d="M67.2123 44.5389C69.0831 41.4817 70.1613 37.8888 70.1613 34.0495C70.1613 22.9306 61.1155 13.8866 49.9984 13.8866C38.8795 13.8866 29.8354 22.9324 29.8354 34.0495C29.8354 37.8888 30.9154 41.4817 32.7845 44.5389C26.177 49.7756 22.2969 57.7202 22.2969 66.2429C22.2969 69.1565 23.5364 74.5653 31.8357 78.0552C36.5865 80.0538 42.8677 81.1107 49.9984 81.1107C57.2389 81.1107 63.5042 80.1158 68.1167 78.2343C76.455 74.8313 77.6998 69.2612 77.6998 66.2429C77.6998 57.7202 73.8198 49.7756 67.2123 44.5389Z" fill="#3F100B" />
        <path className="pointer" d="M70.8943 64.3543C70.8943 70.7242 61.5398 73.7105 49.9989 73.7105C38.4579 73.7105 29.1035 70.3411 29.1035 64.3543C29.1035 57.0534 32.8488 50.6268 38.5218 46.8904C41.4602 49.827 45.5176 51.6429 49.9989 51.6429C54.4801 51.6429 58.5376 49.827 61.476 46.8904C67.1489 50.625 70.8943 57.0517 70.8943 64.3543Z" fill="#04826A" />
        <path className="pointer" d="M69.5252 63.2796C69.5252 68.5465 60.7169 71.0168 49.8534 71.0168C38.9899 71.0168 30.1816 68.2308 30.1816 63.2796C30.1816 57.2432 33.7071 51.9284 39.0484 48.8393C41.8148 51.267 45.6346 52.769 49.8534 52.769C54.0722 52.769 57.8938 51.267 60.6584 48.8393C65.9997 51.9284 69.5252 57.2432 69.5252 63.2796Z" fill="#1AC1A1" />
        <path className="pointer" d="M49.9985 49.522C57.7954 49.522 64.1161 43.2013 64.1161 35.4044C64.1161 27.6074 57.7954 21.2867 49.9985 21.2867C42.2015 21.2867 35.8809 27.6074 35.8809 35.4044C35.8809 43.2013 42.2015 49.522 49.9985 49.522Z" fill="#04826A" />
        <path className="pointer" d="M61.9563 44.3846C64.1233 41.244 60.1049 30.1624 55.9109 28.3234C51.7169 26.4738 42.1887 29.4938 39.6138 34.2694C37.0443 39.0451 41.373 47.5128 46.1486 48.8659C50.9172 50.2154 58.7465 49.0255 61.9563 44.3846Z" fill="#1AC1A1" />
        <path className="pointer" d="M48.9983 49.3429C53.9743 49.3908 61.5288 46.7201 62.5059 42.7265C63.483 38.7259 58.9131 28.2259 53.9052 27.8339C48.8954 27.4403 44.0879 30.1854 44.0879 30.1854C44.0879 30.1854 52.1868 29.6357 54.4726 31.3984C56.7602 33.1575 59.1915 41.9498 56.0456 44.8794C52.8997 47.8125 46.1698 47.4259 44.0879 46.6385C46.1485 48.8659 48.9983 49.3429 48.9983 49.3429Z" fill="#46E0C2" />
        <path className="pointer" d="M38.8471 31.8346C36.744 37.4047 39.5689 42.6857 44.0874 43.122C48.6094 43.5618 53.6138 38.412 52.3228 35.7839C51.0407 33.1575 48.0384 32.1325 46.7474 32.8596C45.4653 33.592 47.7511 37.4916 47.7511 37.4916C47.7511 37.4916 45.8927 40.1889 43.3195 39.6019C40.7429 39.0185 38.8844 35.0621 39.8863 31.8346" fill="#04826A" />
        <path className="pointer" d="M31.5594 64.1628C31.5594 59.3588 33.0791 54.9218 35.6469 51.3308C32.2616 54.4342 30.1797 58.6441 30.1797 63.2796C30.1797 64.7586 30.9671 66.0443 32.3591 67.1207C31.8395 66.2181 31.5594 65.2303 31.5594 64.1628Z" fill="#46E0C2" />
        <path className="pointer" d="M37.5648 69.88C37.3982 65.0051 46.123 54.842 57.7757 57.6102C69.4266 60.3749 61.7799 74.8099 53.2927 70.6692C44.8054 66.5249 52.7057 60.4033 54.3744 62.2759C56.0485 64.1486 52.5426 62.0862 51.7641 65.6311C50.9838 69.176 59.5295 72.7901 61.2497 66.899C62.9698 61.0097 56.1336 58.027 49.2991 59.9156C42.4646 61.8024 42.5072 69.1335 45.8854 71.171" fill="#04826A" />
        <path className="pointer" d="M45.6645 70.8713L45.817 70.882C47.5708 71.0008 49.33 71.0434 51.0891 71.0079L51.6779 70.9955L50.433 69.6105C50.433 69.6105 47.0459 69.6034 46.3614 69.2416C45.6769 68.8799 44.2085 63.5669 46.583 62.0702C42.5788 64.8153 44.3681 70.0662 45.6645 70.8713Z" fill="#46E0C2" />
    </svg>
}

export default CIAvatar;