import { useEffect } from "react";
import { makeStyles } from "@material-ui/core";
import { anime } from "../libs/utils";
import CILabel from "../shared/CILabel";
import { Howl } from "howler";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
    root: {
        textAlign: "center",
        display: "flex",
        justifyContent: "center",
        width: "20vw"
    },
    svg: {
        width: "20vw"
    },
    label: {
        position: "absolute",
        color: theme.palette.primary.light,
        marginTop: "1.8vh"
    }
}));

const Option = ({ active, id, multiple, onClick, text }) => {
    const cls = useStyles();

    useEffect(() => {
        anime({
            targets: [`#option${id} #path-1`, `#option${id} #path-2`],
            fill: active ? "#04826A" : "#723600",
            easing: "linear",
            duration: 250
        });
        anime({
            targets: [`#option${id} #path-3`, `#option${id} #path-4`, `#option${id} #path-5`, `#option${id} #path-6`],
            fill: active ? "#30D0B1" : "#B76400",
            easing: "linear",
            duration: 250
        });
        anime({
            targets: `#option${id} #path-transparent`,
            opacity: active ? 1 : 0,
            easing: "linear",
            duration: 250
        });
    }, [active]);

    const handleClick = () => {
        new Howl({
            src: require("../assets/sounds/Click.mp3"),
            autoplay: true
        });
        anime({
            targets: "#option" + id,
            scale: [0.9, 1],
            duration: 1000
        });
        onClick && onClick();
    }

    return <div className={cls.root} id={"option" + id} onClick={handleClick}>
        <CILabel className={clsx(cls.label, "pointer")}>{text}</CILabel>
        {multiple ? <svg className={clsx(cls.svg, "pointer")} viewBox="0 0 360 78">
            <path className="pointer" opacity="0.3" d="M5.18129 71.864C13.2998 80.6152 343.596 79.4539 354.6 71.864C361.633 67.0134 360.94 15.6613 357.371 11.6951C352.86 6.67828 12.4997 3.45685 3.40414 9.7725C-2.98248 14.2045 0.787784 67.1283 5.18129 71.864Z" fill="black" />
            <path className="pointer" d="M5.18129 65.5877C13.2998 74.3389 343.596 73.1776 354.6 65.5877C361.633 60.7371 360.94 9.38507 357.371 5.41881C352.86 0.402 12.4997 -2.81738 3.40414 3.49621C-2.98248 7.92824 0.787784 60.852 5.18129 65.5877Z" fill="#3F100B" />
            <path className="pointer" id="path-1" d="M356.069 25.426C354.247 22.5124 346.202 21.0371 346.202 21.0371C346.202 21.0371 355.335 17.7213 355.061 16.1577C354.79 14.5942 356.448 7.23419 349.999 5.20079C343.549 3.16944 15.1448 -0.864529 7.32246 3.95941C3.14287 6.5345 3.65092 35.2935 5.2594 37.3186C6.86583 39.3438 15.3134 40.2815 15.3134 40.2815C15.3134 40.2815 7.66801 41.4634 6.05336 43.8128C4.44076 46.1622 7.75029 57.8701 9.35466 60.2811C10.3502 61.781 202.272 66.1371 251.065 63.246C270.571 53.235 300.722 53.828 309.73 54.4456C317.585 54.9832 320.769 58.2826 332.987 60.753C342.722 59.5034 351.192 59.3044 352.315 58.4282C355.386 56.044 357.891 28.3376 356.069 25.426Z" fill="#723600" />
            <path className="pointer" d="M17.5203 11.1936C12.6332 14.1278 15.3215 48.7879 19.1144 52.6947C24.9333 58.6902 336.829 58.4789 343.777 53.3554C348.053 50.2017 348.87 18.0489 345.135 13.6641C341.401 9.27925 27.4263 5.24529 17.5203 11.1936Z" fill="#3F100B" />
            <path className="pointer" d="M21.0379 14.1418C16.1672 17.8639 16.5539 46.0237 22.5065 50.6876C28.2164 55.1606 332.205 54.4979 339.229 51.6663C346.252 48.8327 345.378 20.1681 342.169 17.2216C336.052 11.6077 29.0021 8.05594 21.0379 14.1418Z" fill="#62281F" />
            <path className="pointer" id="path-2" d="M256.268 63.2474C253.269 64.4888 301.141 63.2474 312.194 62.876C320.467 62.597 326.087 61.2222 324.879 60.1573C322.962 58.4604 285.661 51.0757 256.268 63.2474Z" fill="#723600" />
            <path className="pointer" id="path-3" d="M5.06657 28.5677C4.38986 21.7021 5.27226 7.22006 7.29418 4.61419C9.31815 2.00832 263.897 1.84623 294.536 3.15942C262.625 3.22508 13.0555 3.14916 9.24616 6.83842C5.43887 10.5297 5.23318 24.0802 5.06657 28.5677Z" fill="#B76400" />
            <path className="pointer" id="path-4" d="M348.154 27.4062C348.292 35.6055 347.319 51.5731 344.672 53.5942C342.023 55.6174 324.052 56.1837 323.494 56.3868C330.321 57.0065 344.947 57.0065 346.899 54.7843C348.851 52.5601 349.01 31.1468 348.154 27.4062Z" fill="#B76400" />
            <path className="pointer" id="path-5" d="M262.254 61.0644C275.848 57.1454 297.986 55.3808 309.276 57.0982C283.512 58.752 262.254 61.0644 262.254 61.0644Z" fill="#B76400" />
            <path className="pointer" id="path-6" d="M39.6074 56.3867C50.7516 58.1185 260.214 59.5014 260.214 59.5014L263.588 57.9441L39.6074 56.3867Z" fill="#B76400" />
            <path className="pointer" d="M324.015 15.2677C339.322 24.0148 333.028 46.2016 314.218 47.7733C295.406 49.3451 297.504 25.3649 310.221 23.3623C322.937 21.3597 319.42 41.1889 313.138 37.0318C306.854 32.8788 320.065 31.3338 315.141 27.3511C310.221 23.3623 297.613 31.8816 303.898 41.8086C310.188 51.7334 323.503 43.4931 327.269 36.5168C331.033 29.5404 318.827 13.0496 303.824 13.54C316.661 13.9216 324.015 15.2677 324.015 15.2677Z" fill="#3F100B" />
            <path className="pointer" d="M33.5023 23.2196C34.5858 23.1099 39.2517 26.0971 39.9526 26.174C40.6535 26.251 47.3556 15.7507 48.5094 15.4872C49.6671 15.2256 60.5373 22.6849 60.6935 24.2967C60.8497 25.9086 44.722 46.3648 42.7736 46.4418C40.8272 46.5168 25.3145 35.2125 24.9982 33.3333C24.76 31.9195 31.6691 23.4062 33.5023 23.2196Z" fill="#3F100B" />
            <path className="pointer" id="path-transparent" opacity="0" d="M33.7856 26.6643C34.6075 26.6027 39.8667 30.6555 40.4817 30.7228C41.0966 30.7901 48.7238 19.2069 49.6804 18.803C50.635 18.3991 56.7845 23.2732 56.9211 24.6869C57.0578 26.1007 43.9429 41.8194 42.2347 41.8867C40.5266 41.954 29.4322 34.3467 29.1608 33.1195C28.8856 31.8923 32.9364 26.7297 33.7856 26.6643Z" fill="#04826A" />
            <path className="pointer" id="path-transparent" opacity="0" d="M33.2516 27.7542C32.6074 27.8581 30.4717 30.6972 30.4717 31.5185C30.4717 32.5456 40.9882 39.3778 42.3059 39.022C43.6256 38.6662 54.8371 24.9614 54.8371 24.0746C54.8371 23.1898 50.9385 19.4987 49.859 19.6448C48.7814 19.7891 41.6441 31.907 41.0448 32.0244C40.4455 32.1436 34.0423 27.6273 33.2516 27.7542Z" fill="#1AC1A1" />
            <path className="pointer" id="path-transparent" opacity="0" d="M52.2561 21.0352C53.242 21.9392 50.7139 26.2843 50.7139 26.2843L54.8369 22.322" fill="#04826A" />
            <path className="pointer" id="path-transparent" opacity="0" d="M33.8173 27.2114C34.6236 27.2268 40.5173 31.9316 41.019 31.7316C41.6339 31.4854 47.8732 22.0026 48.3495 21.4564C49.0094 20.7004 42.3075 32.6164 41.2084 32.9819C40.0722 33.3608 34.5065 28.4521 33.8661 28.5694C33.2278 28.6867 30.6392 30.8353 30.6392 30.8353C30.6392 30.8353 33.0111 27.196 33.8173 27.2114Z" fill="#46E0C2" />
            <path className="pointer" id="path-transparent" opacity="0" d="M53.0772 27.7983C49.9185 24.4072 37.1824 30.3315 40.9384 40.2971C41.9848 41.2838 44.6495 37.962 44.6495 37.962C40.3879 36.5982 41.8872 25.7767 51.8141 29.4236" fill="#04826A" />
            <path className="pointer" id="path-transparent" opacity="0" d="M46.3349 36.2049C43.8927 36.6608 43.5257 34.0006 45.1187 32.1771C46.7137 30.3537 49.7044 32.1387 49.4936 32.885C49.2827 33.6313 48.1934 33.8198 48.1934 33.8198C47.8088 32.056 45.2807 32.0963 44.902 33.5486C44.5233 35.0008 45.6809 35.9145 46.8288 35.2912" fill="#04826A" />
            <path className="pointer" id="path-transparent" opacity="0" d="M44.9137 36.2572C44.8239 36.036 43.3441 35.3801 44.3241 32.7949C43.3285 33.4162 42.9458 36.3822 43.8966 37.1516C44.2031 37.192 45.0074 36.488 44.9137 36.2572Z" fill="#46E0C2" />
        </svg> : <svg className={clsx(cls.svg, "pointer")} viewBox="0 0 360 78">
            <path className="pointer" opacity="0.3" d="M5.18129 71.8645C13.2998 80.6157 343.596 79.4543 354.6 71.8645C361.633 67.0139 360.94 15.6618 357.371 11.6956C352.86 6.67877 12.4997 3.45734 3.40414 9.77298C-2.98248 14.205 0.787784 67.1288 5.18129 71.8645Z" fill="black" />
            <path className="pointer" d="M5.18129 65.5877C13.2998 74.3389 343.596 73.1776 354.6 65.5877C361.633 60.7371 360.94 9.38507 357.371 5.41881C352.86 0.402 12.4997 -2.81738 3.40414 3.49621C-2.98248 7.92824 0.787784 60.852 5.18129 65.5877Z" fill="#3F100B" />
            <path className="pointer" id="path-1" d="M356.069 25.4265C354.247 22.5129 346.202 21.0376 346.202 21.0376C346.202 21.0376 355.335 17.7218 355.061 16.1582C354.79 14.5947 356.448 7.23467 349.999 5.20127C343.549 3.16993 15.1448 -0.864041 7.32246 3.9599C3.14287 6.53499 3.65092 35.2939 5.2594 37.3191C6.86583 39.3443 15.3134 40.282 15.3134 40.282C15.3134 40.282 7.66801 41.4639 6.05336 43.8133C4.44076 46.1627 7.75029 57.8706 9.35466 60.2816C10.3502 61.7815 202.272 66.1376 251.065 63.2465C270.571 53.2355 300.722 53.8284 309.73 54.4461C317.585 54.9836 320.769 58.283 332.987 60.7535C342.722 59.5039 351.192 59.3049 352.315 58.4287C355.386 56.0445 357.891 28.3381 356.069 25.4265Z" fill="#723600" />
            <path className="pointer" d="M17.5203 11.1927C12.6332 14.1268 15.3215 48.7869 19.1144 52.6937C24.9333 58.6893 336.829 58.4779 343.777 53.3544C348.053 50.2007 348.87 18.048 345.135 13.6631C341.401 9.27828 27.4263 5.24431 17.5203 11.1927Z" fill="#3F100B" />
            <path className="pointer" d="M21.0379 14.1413C16.1672 17.8634 16.5539 46.0232 22.5065 50.6871C28.2164 55.1601 332.205 54.4974 339.229 51.6658C346.252 48.8322 345.378 20.1676 342.169 17.2211C336.052 11.6072 29.0021 8.05545 21.0379 14.1413Z" fill="#62281F" />
            <path className="pointer" id="path-2" d="M256.268 63.2484C253.269 64.4897 301.141 63.2484 312.194 62.877C320.467 62.5979 326.087 61.2232 324.879 60.1583C322.962 58.4614 285.661 51.0767 256.268 63.2484Z" fill="#723600" />
            <path className="pointer" id="path-3" d="M5.06657 28.5682C4.38986 21.7026 5.27226 7.22055 7.29418 4.61468C9.31815 2.00881 263.897 1.84672 294.536 3.15991C262.625 3.22557 13.0555 3.14965 9.24616 6.8389C5.43887 10.5302 5.23318 24.0807 5.06657 28.5682Z" fill="#B76400" />
            <path className="pointer" id="path-4" d="M348.154 27.4067C348.292 35.606 347.319 51.5736 344.672 53.5947C342.023 55.6178 324.052 56.1842 323.494 56.3873C330.321 57.007 344.947 57.007 346.899 54.7848C348.851 52.5606 349.01 31.1473 348.154 27.4067Z" fill="#B76400" />
            <path className="pointer" id="path-5" d="M262.254 61.0634C275.848 57.1444 297.986 55.3798 309.276 57.0972C283.512 58.751 262.254 61.0634 262.254 61.0634Z" fill="#B76400" />
            <path className="pointer" id="path-6" d="M39.6074 56.3872C50.7516 58.119 260.214 59.5019 260.214 59.5019L263.588 57.9446L39.6074 56.3872Z" fill="#B76400" />
            <path className="pointer" d="M324.014 15.2677C339.322 24.0148 333.028 46.2016 314.218 47.7733C295.405 49.3451 297.503 25.3649 310.221 23.3623C322.937 21.3597 319.419 41.1889 313.138 37.0318C306.854 32.8788 320.065 31.3338 315.141 27.3511C310.221 23.3623 297.612 31.8816 303.898 41.8086C310.188 51.7334 323.502 43.4931 327.268 36.5168C331.033 29.5404 318.827 13.0496 303.824 13.54C316.661 13.9216 324.014 15.2677 324.014 15.2677Z" fill="#3F100B" />
            <path className="pointer" opacity="0.3" d="M55.9996 32.9694C56.0569 41.2132 49.3885 47.9422 41.1043 47.9998C32.8202 48.058 26.0576 41.4221 26.0004 33.1783C25.9425 24.9345 32.6103 18.2049 40.895 18.1473C49.1786 18.0897 55.9405 24.7257 55.9996 32.9694Z" fill="black" />
            <path className="pointer" d="M55.9996 31.0329C56.0569 39.2767 49.3885 46.0056 41.1043 46.0633C32.8202 46.1215 26.0576 39.4856 26.0004 31.2418C25.9425 22.998 32.6103 16.2684 40.895 16.2108C49.1786 16.1532 55.9405 22.7891 55.9996 31.0329Z" fill="#552800" />
            <path className="pointer" d="M28.7426 23.6278C28.2547 25.1021 32.8137 22.5587 41.0101 22.8264C49.2064 23.0942 52.0741 22.6119 52.5257 21.6174C52.9773 20.6236 48.4253 16.9356 43.9133 16.1753C39.4013 15.4164 30.941 16.9818 28.7426 23.6278Z" fill="#A85C09" />
            <path className="pointer" d="M50.7176 19.5691C51.7156 20.3749 52.3397 20.8263 51.9542 21.4745C51.5693 22.124 50.2068 22.5956 49.481 22.3082C46.9397 19.7546 40.0429 17.1048 34.3516 21.1833C38.4202 17.8866 42.6274 18.6304 44.7718 19.0918C47.9365 19.7723 49.1349 20.963 49.6852 21.07C52.5261 21.6176 50.7176 19.5691 50.7176 19.5691Z" fill="#B76400" />
            <path className="pointer" d="M29.133 23.398C28.0192 23.6905 27.3347 26.8233 27.3519 29.2553C27.7043 29.9307 32.131 30.184 32.131 30.184C32.131 30.184 28.1896 30.1339 27.7933 30.9866C27.3977 31.8387 28.1127 34.7271 29.4803 36.918C30.0217 37.7852 50.8131 39.8235 52.3888 38.3625C55.6839 35.3057 55.8461 28.0051 55.245 26.941C54.6457 25.8756 48.7368 26.1194 48.7368 26.1194C48.7368 26.1194 53.9816 26.2327 54.3048 25.5408C54.7004 24.6932 53.4352 23.7063 52.1534 23.4309C50.871 23.1556 32.3231 22.5618 29.133 23.398Z" fill="#A85C09" />
            <path className="pointer" d="M29.5509 36.4363C28.8766 37.3751 32.2309 43.3413 40.5017 43.8217C48.5028 44.2864 49.7623 41.4758 49.5981 41.2947C49.4334 41.1137 43.737 40.8029 43.737 40.8029C43.737 40.8029 49.6745 40.6414 50.5663 40.2996C51.4594 39.9578 53.2374 37.6055 52.4995 37.1086C51.7616 36.6123 30.4377 35.2019 29.5509 36.4363Z" fill="#A85C09" />
            <path className="pointer" d="M52.5569 30.2051C52.5995 36.3587 47.6226 41.3817 41.4389 41.4247C35.2558 41.4678 30.2076 36.5144 30.1643 30.3608C30.1211 24.2073 35.0993 19.1849 41.2824 19.1419C47.4654 19.0982 52.5137 24.0516 52.5569 30.2051Z" fill="#3F100B" />
            <path className="pointer" id="path-transparent" opacity="0" d="M49.9998 30.2107C50.035 35.1564 46.0329 39.1944 41.0632 39.2294C36.0923 39.2639 32.0349 35.2821 32.0002 30.3358C31.965 25.3906 35.9665 21.3521 40.9368 21.3176C45.9071 21.2826 49.9646 25.2649 49.9998 30.2107Z" fill="#04826A" />
            <path className="pointer" d="M33.2311 21.9629C30.8208 22.7953 29.8934 26.5757 29.8438 27.5315C30.7947 25.316 33.2311 21.9629 33.2311 21.9629Z" fill="#B76400" />
            <path className="pointer" id="path-transparent" opacity="0" d="M41.2907 22.0993C31.6407 21.1996 30.3537 37.5457 40.8385 37.7349C51.3234 37.9252 52.5994 23.1531 41.2907 22.0993Z" fill="#1AC1A1" />
            <path className="pointer" id="path-transparent" opacity="0" d="M46.25 37.4466C46.1827 37.094 42.9403 31.868 41.1922 31.7675C39.4448 31.667 37.0947 36.93 36.5269 37.3608C36.3185 37.5183 37.3879 37.7369 37.3879 37.7369C37.3879 37.7369 40.1269 32.495 41.3722 32.4627C42.6187 32.4316 45.1115 37.5294 45.1115 37.5294" fill="#04826A" />
            <path className="pointer" id="path-transparent" opacity="0" d="M44 37.7055C43.9584 37.5193 42.235 34.805 41.1517 34.7522C40.0678 34.6989 38.693 37.5845 38.7518 37.6983C38.8119 37.8117 39.2967 37.6388 39.2967 37.6388C39.2967 37.6388 40.5976 35.2742 41.3699 35.2573C42.1415 35.2409 43.2843 37.644 43.2843 37.644" fill="#04826A" />
            <path className="pointer" id="path-transparent" opacity="0" d="M36.5 23.1707C37.2 24.2657 40.0368 27.9531 41.6551 28.0333C43.254 28.1129 45.3321 24.5051 46.2352 23.3138C46.3694 23.1373 45.5492 22.79 45.3773 23.0611C44.6272 24.2374 42.5296 27.3797 41.4661 27.4033C40.1569 27.4316 37.25 22.437 37.25 22.437" fill="#04826A" />
            <path className="pointer" id="path-transparent" opacity="0" d="M38.75 22.3104C39.2043 23.2351 40.6467 25.7369 41.6106 25.7943C42.5051 25.8475 43.5894 23.5729 43.9865 22.5154C44.0959 22.2237 43.5091 22.1974 43.4118 22.4C43.0299 23.1903 42.0289 25.1248 41.3819 25.1421C40.5683 25.1636 39.3654 22.0635 39.3654 22.0635" fill="#04826A" />
        </svg>}
    </div>
}

export default Option;