import { makeStyles } from "@material-ui/core";
import { anime } from "../libs/utils";
import { Howl } from "howler";
import clsx from "clsx";

const useStyles = makeStyles({
    svg: {
        filter: "drop-shadow(0.33vh 0.66vh 0.8vh black)",
        height: "10vh",
        outline: "none"
    }
});

const CILogout = (props) => {
    const cls = useStyles();

    const handleClick = () => {
        new Howl({
            src: require("../assets/sounds/Click.mp3"),
            autoplay: true
        });
        anime({
            targets: "#" + props.id,
            scale: [0.9, 1],
            duration: 1000
        });
        anime({
            targets: `#${props.id} path[fill="#04826A"],#${props.id} path[fill="#1AC1A1"],#${props.id} path[fill="#46E0C2"]`,
            filter: "sepia(1)",
            easing: "linear",
            duration: 1000
        });
        props.onClick && props.onClick();
    }

    return <svg className={clsx(cls.svg, props.className, "pointer")} id={props.id} onClick={handleClick} viewBox="0 0 101 111" data-tooltip-id="tooltip"
        data-tooltip-content="Logout">
        <path className="pointer" opacity="0.3" d="M100.995 58.4267C101.192 86.8849 78.9702 110.112 51.3579 110.305C23.742 110.498 1.19428 87.5854 0.997438 59.1254C0.797053 30.6672 23.0203 7.43854 50.6344 7.24702C78.2467 7.05196 100.793 29.9668 100.995 58.4267Z" fill="black" />
        <path className="pointer" d="M100.995 51.1792C101.192 79.6374 78.9702 102.864 51.3579 103.058C23.742 103.253 1.19428 80.3396 0.997438 51.8796C0.797053 23.4214 23.0203 0.192754 50.6344 0.0012362C78.2467 -0.193829 100.793 22.721 100.995 51.1792Z" fill="#552800" />
        <path className="pointer" d="M10.6638 26.1045C9.11388 30.8056 23.5824 22.6926 49.5934 23.5492C75.608 24.4021 84.7086 22.8647 86.1397 19.6922C87.5743 16.5215 73.1271 4.76086 58.8076 2.33673C44.4898 -0.0838463 17.6418 4.90982 10.6638 26.1045Z" fill="#A85C09" />
        <path className="pointer" d="M11.9022 25.3721C8.36445 26.3048 6.19214 36.2939 6.24711 44.0504C7.3643 46.205 18.5185 47.0136 18.5185 47.0136C18.5185 47.0136 8.90709 46.8558 7.64981 49.5725C6.39252 52.291 8.6606 61.5034 13.0017 68.4903C14.72 71.2567 82.5069 77.6158 87.6371 73.0974C96.11 65.6353 96.6757 40.0658 94.7711 36.6699C92.8666 33.274 74.1155 34.0489 74.1155 34.0489C74.1155 34.0489 90.7599 34.4089 91.7866 32.2047C93.0421 29.5004 89.0273 26.3527 84.9576 25.4749C80.8896 24.5971 22.0243 22.705 11.9022 25.3721Z" fill="#A85C09" />
        <path className="pointer" d="M15.451 71.5032C14.3657 72.9679 24.0179 89.2381 47.986 90.5078C73.387 91.852 77.377 83.0262 76.8556 82.4481C76.3307 81.87 58.2553 80.8805 58.2553 80.8805C58.2553 80.8805 77.1003 80.3662 79.9305 79.2756C82.7643 78.1832 88.407 70.6839 86.0627 69.0968C83.7219 67.515 17.0381 69.361 15.451 71.5032Z" fill="#A85C09" />
        <path className="pointer" d="M24.3778 21.9088C20.7584 27.5781 21.7267 66.8447 24.7821 70.2459C27.8393 73.6471 77.2297 73.9858 79.5209 70.5349C81.812 67.0823 83.2803 24.8631 79.1591 21.9088C74.8163 18.7948 26.1085 19.1992 24.3778 21.9088Z" fill="#3F100B" />
        <path className="pointer" d="M58.2323 18.5306C61.6194 19.2116 25.7345 18.0341 23.5835 21.446C20.1095 26.9574 20.411 53.582 22.3634 63.3477C20.411 55.5362 21.2427 51.4062 19.6556 51.1508C18.0685 50.8955 11.8282 50.2872 10.5549 52.1173C9.27989 53.9509 10.7305 60.4199 10.5549 60.6629C10.3776 60.9094 6.22801 52.2662 8.66987 50.0514C11.11 47.84 19.425 48.2106 19.668 46.9906C19.9127 45.7688 13.6618 45.5258 11.0443 44.1817C15.3837 43.9387 18.2139 44.2881 18.5809 42.6992C18.9462 41.1121 18.2139 22.2422 21.5087 19.412C24.8071 16.5835 48.4383 16.5605 58.2323 18.5306Z" fill="#B76400" />
        <path className="pointer" d="M46.4073 3.40952C27.0994 1.57769 13.2285 17.6918 10.5508 28.8619C15.5657 20.4369 27.6243 6.33904 46.4073 3.40952Z" fill="#B76400" />
        <path className="pointer" d="M82.897 44.742C82.7037 53.5767 81.7266 68.9655 79.9196 71.0988C78.1125 73.2321 61.8707 74.3369 53.8926 73.6737C63.0837 75.3424 79.7617 75.8318 81.7603 73.4325C84.1046 70.6165 83.9361 52.1155 82.897 44.742Z" fill="#B76400" />
        <path className="pointer" d="M36.6729 30.5218C38.0933 31.0343 39.7052 38.9681 39.5988 46.6448C39.5669 48.9483 38.9835 57.9071 36.6729 59.2974C35.0379 60.2816 18.4095 52.5624 15.7637 44.6339C16.4588 40.1757 30.2889 28.2236 36.6729 30.5218Z" fill="#3F100B" />
        <path className="pointer" d="M74.654 26.1328C73.6929 25.4288 42.8088 24.3773 41.6047 25.8509C40.3474 27.3884 41.1401 34.7334 41.6047 35.2637C42.1438 35.8808 54.9932 36.0776 64.578 35.9748C64.3475 41.6192 64.2216 49.4076 64.367 55.0432C55.2131 54.7914 43.5163 54.8517 42.7786 55.7561C41.5178 57.2954 42.3016 64.972 42.8017 65.4668C44.1778 66.8305 73.5102 66.4439 74.0635 65.4278C75.9361 61.9893 75.4431 26.7092 74.654 26.1328Z" fill="#04826A" />
        <path className="pointer" d="M72.9323 31.0839C72.9926 29.2415 72.6486 26.773 72.4269 26.5247C71.5863 25.5831 45.0362 25.287 44.0201 26.1133C43.3321 26.6755 44.0201 32.3164 44.3925 32.8076C44.6585 33.164 58.6429 33.313 66.7345 33.2562C66.5022 37.9946 66.4455 48.7604 66.5519 55.9104C59.5898 55.5522 45.9406 55.5575 44.7596 56.3661C43.9918 56.8892 44.4865 63.1703 45.132 63.7467C45.6108 64.1758 59.1997 64.5411 66.4863 64.2751C68.937 64.1864 72.7975 64.1421 72.9163 64.0304C74.1399 62.8848 73.9324 34.5277 72.9323 31.0839Z" fill="#1AC1A1" />
        <path className="pointer" d="M44.4537 32.2366C43.9447 31.6815 43.1343 26.6577 44.2799 26.0176C45.4308 25.3739 67.3224 25.4395 68.2569 26.1825C69.1915 26.9291 47.0888 26.4308 45.9947 27.5905C44.7463 28.9187 44.8686 32.6923 44.4537 32.2366Z" fill="#46E0C2" />
        <path className="pointer" d="M61.7265 64.3603C63.115 64.5713 72.0897 64.8196 72.9161 64.0304C73.746 63.2378 73.7637 58.3239 73.6449 57.7564C73.5297 57.189 73.3417 61.9876 72.1642 62.4646C70.985 62.9381 60.4355 64.1634 61.7265 64.3603Z" fill="#46E0C2" />
        <path className="pointer" d="M47.0039 34.1997C47.0039 34.1997 53.3081 27.5143 55.3225 27.3671C57.337 27.2217 66.6133 33.0789 66.6895 33.2669C66.7675 33.4549 63.9763 35.1856 63.9763 35.1856C63.9763 35.1856 56.9522 28.3708 55.8723 28.3708C54.7923 28.3708 49.1549 34.4337 49.1549 34.4337" fill="#04826A" />
        <path className="pointer" d="M66.3782 55.4422C66.3782 55.4422 60.074 62.1276 58.0595 62.2748C56.0433 62.4202 46.7688 55.8696 46.6908 55.6834C46.6146 55.4954 49.787 55.2259 49.787 55.2259C49.787 55.2259 56.4316 61.2729 57.5116 61.2729C58.5915 61.2729 64.2289 55.2099 64.2289 55.2099" fill="#04826A" />
        <path className="pointer" d="M65.7935 36.2939C68.8898 36.2585 75.7312 41.8125 73.8107 48.7267C71.8902 55.6391 62.8357 50.8866 65.5861 45.8805C68.3365 40.8744 72.1101 45.6659 70.8972 46.6359C69.6842 47.6095 71.0426 45.5453 68.8029 45.0346C66.5649 44.5221 64.1479 49.5708 67.8648 50.6738C71.5781 51.7786 73.5695 47.7407 72.4683 43.639C71.3653 39.5373 66.7121 39.4664 65.3697 41.4543" fill="#04826A" />
        <path className="pointer" d="M34.3859 33.6642C35.9393 33.8929 36.8969 43.0521 36.9696 44.7084C37.1735 49.4041 36.3064 54.9848 35.1466 55.4033C33.9886 55.8218 21.4921 50.461 19.0254 44.2952C19.8358 41.5483 29.1936 32.8999 34.3859 33.6642Z" fill="#04826A" />
        <path className="pointer" d="M34.1213 34.5153C35.3821 34.6288 35.8201 43.2666 35.8166 44.6037C35.813 45.9408 35.6605 52.4755 34.7242 52.8142C33.7879 53.1529 22.0343 47.5989 21.3906 43.5575C21.7329 40.4276 28.7765 34.0401 34.1213 34.5153Z" fill="#1AC1A1" />
        <path className="pointer" d="M33.7592 34.5969C32.3848 34.0188 28.4339 35.8365 26.196 37.9183C23.958 40.0002 24.5574 39.7661 25.3235 39.243C27.8646 37.5105 30.8367 36.3862 32.1951 36.5032C33.5534 36.6185 35.0554 41.1121 35.1849 42.8801C35.3126 44.6516 35.614 44.7119 35.5874 41.9845C35.5591 39.259 35.3144 35.2548 33.7592 34.5969Z" fill="#46E0C2" />
        <path className="pointer" d="M27.0771 51.1437C33.5231 52.683 36.8286 44.9336 32.2375 39.963C27.3874 34.7122 22.0639 41.7203 25.1602 45.4798C28.2564 49.2392 32.0991 43.0432 29.315 42.5627C26.5309 42.0821 29.879 45.8415 27.5187 45.6589C25.1584 45.4798 23.6812 40.0038 27.8733 38.9416C32.0655 37.8793 33.3387 43.46 32.6985 46.2813C32.0601 49.1009 25.0715 50.4185 20.9609 46.4852C24.676 49.64 27.0771 51.1437 27.0771 51.1437Z" fill="#04826A" />
        <path className="pointer" d="M27.8802 39.0799C30.8576 38.4397 32.6806 41.9952 32.6806 44.5665C32.6806 47.1361 31.6077 48.5015 28.2863 48.8101C30.8487 48.404 33.6878 45.1251 31.1466 41.5572C28.6073 37.9875 26.0076 40.1456 25.6511 41.6866C25.5696 39.8158 27.3571 39.1508 27.8802 39.0799Z" fill="#46E0C2" />
        <path className="pointer" d="M36.3031 40.8479C36.6116 40.7255 45.3292 40.0658 46.168 40.6191C47.2232 41.3178 47.5636 48.9253 46.8703 49.7482C46.1769 50.571 37.3688 49.8386 36.6719 49.3155C35.9768 48.7888 36.3031 40.8479 36.3031 40.8479Z" fill="#04826A" />
        <path className="pointer" d="M37.3401 41.621C37.0351 41.8675 37.2798 46.9534 37.56 47.3329C37.9519 47.8649 45.1799 48.3153 45.5647 48.0475C45.9495 47.7798 46.4248 41.8799 45.6818 41.3674C44.7508 40.7237 38.0831 41.0181 37.3401 41.621Z" fill="#1AC1A1" />
        <path className="pointer" d="M39.1212 41.449C39.5947 41.1014 45.053 40.6829 45.7782 41.5785C46.4698 42.435 45.6417 47.2282 45.4732 47.5705C45.2604 47.9961 44.7302 42.4332 44.2159 42.0892C43.652 41.7132 38.6335 41.8072 39.1212 41.449Z" fill="#46E0C2" />
    </svg>
}

export default CILogout;