import { useEffect } from "react";
import { makeStyles } from "@material-ui/core";
import { anime } from "../libs/utils";
import { Howl } from "howler";
import clsx from "clsx";

const useStyles = makeStyles({
    svg: {
        height: "15vh"
    }
});

const Rating = ({ active, className, id, onClick }) => {
    const cls = useStyles();

    useEffect(() => {
        anime({
            targets: `#rating${id} path[fill="#04826A"], #rating${id} path[fill="#1AC1A1"]`,
            opacity: x => active ? (x.id === "path-rating" ? 0.5 : 1) : 0,
            easing: "linear",
            duration: 250
        });

        if (active)
            anime({
                targets: "#rating" + id,
                scale: [0.9, 1],
                duration: 1000
            });
    }, [active]);

    const handleClick = () => {
        new Howl({
            src: require("../assets/sounds/Rating.mp3"),
            autoplay: true
        });
        onClick && onClick();
    }

    return <svg className={clsx(cls.svg, className, "pointer")} id={"rating" + id} onClick={handleClick} viewBox="0 0 115 123">
        <path className="pointer" opacity="0.3" d="M114.72 65.2253C114.939 96.9121 89.4386 122.776 57.7596 122.998C26.0807 123.222 0.220359 97.715 0.00143047 66.0282C-0.219931 34.3415 25.2779 8.4748 56.9593 8.25338C88.6359 8.03197 114.494 33.5386 114.72 65.2253Z" fill="black" />
        <path className="pointer" d="M114.72 57.782C114.939 89.4687 89.4386 115.333 57.7596 115.554C26.0807 115.778 0.220359 90.2716 0.00143047 58.5849C-0.219931 26.8981 25.2779 1.03144 56.9593 0.810024C88.6359 0.588609 114.494 26.0952 114.72 57.782Z" fill="#552800" />
        <path className="pointer" d="M10.4914 29.3193C8.6256 34.9861 26.0596 25.2097 57.4029 26.2389C88.7461 27.2682 99.712 25.4141 101.439 21.5917C103.166 17.7716 85.759 3.59617 68.505 0.673968C51.251 -2.24336 18.8982 3.77379 10.4914 29.3193Z" fill="#A85C09" />
        <path className="pointer" d="M94.5219 13.718C98.3385 16.8154 100.725 18.5502 99.2507 21.0417C97.779 23.5381 92.5685 25.3508 89.793 24.2462C80.075 14.4309 53.7014 4.24576 31.9375 19.9225C47.496 7.25068 63.5848 10.1096 71.7849 11.8834C83.8868 14.499 88.4697 19.0757 90.5739 19.4869C101.438 21.5916 94.5219 13.718 94.5219 13.718Z" fill="#B76400" />
        <path className="pointer" d="M11.9783 28.436C7.71896 29.5602 5.10154 41.6018 5.16722 50.9499C6.51485 53.546 23.4429 54.5193 23.4429 54.5193C23.4429 54.5193 8.37088 54.3271 6.85541 57.6045C5.34237 60.8795 8.07654 71.9819 13.3065 80.403C15.3766 83.7364 94.8841 91.5711 100.91 85.9554C113.51 74.2058 114.13 46.1444 111.832 42.0543C109.54 37.9593 86.9443 38.8961 86.9443 38.8961C86.9443 38.8961 107.001 39.3316 108.236 36.6722C109.749 33.4142 104.911 29.621 100.009 28.5626C95.1055 27.5042 24.1775 25.2219 11.9783 28.436Z" fill="#A85C09" />
        <path className="pointer" d="M13.5815 78.5514C11.003 82.1598 23.8298 105.092 55.4576 106.939C86.0541 108.725 90.8705 97.9216 90.2429 97.2258C89.6129 96.5299 67.8295 95.3352 67.8295 95.3352C67.8295 95.3352 90.5348 94.7148 93.9453 93.4009C97.3605 92.087 104.16 83.0454 101.338 81.1354C98.516 79.2278 16.9724 73.8068 13.5815 78.5514Z" fill="#A85C09" />
        <path className="pointer" d="M101.557 54.5998C101.72 78.2523 82.6879 97.5593 59.0412 97.7247C35.3969 97.8902 16.0923 78.8508 15.9269 55.1983C15.7614 31.5458 34.7985 12.2413 58.4428 12.0758C82.087 11.9079 101.392 30.9472 101.557 54.5998Z" fill="#3F100B" />
        <path className="pointer" d="M95.3879 54.8841C95.5363 75.8432 78.6642 92.9555 57.7128 93.1039C36.7564 93.2499 19.6508 76.376 19.5049 55.4146C19.3565 34.4579 36.2262 17.3432 57.18 17.1973C78.1339 17.0488 95.2395 33.9251 95.3879 54.8841Z" fill="#04826A" />
        <path className="pointer" d="M27.6525 22.9199C18.4356 26.1195 14.889 40.6502 14.6992 44.3242C18.3359 35.8083 27.6525 22.9199 27.6525 22.9199Z" fill="#B76400" />
        <path className="pointer" d="M58.6988 19.8543C18.5206 16.1049 13.1617 84.2279 56.816 85.0162C100.47 85.8094 105.783 24.2462 58.6988 19.8543Z" fill="#1AC1A1" />
        <path className="pointer" id="path-rating" opacity="0.5" d="M79.1733 86.0676C78.8912 84.6077 65.3054 62.9698 57.9811 62.5537C50.6591 62.1377 40.8122 83.9289 38.4332 85.7123C37.5599 86.3644 42.0406 87.2696 42.0406 87.2696C42.0406 87.2696 53.5174 65.566 58.7352 65.4321C63.9578 65.3032 74.4031 86.4107 74.4031 86.4107" fill="#04826A" />
        <path className="pointer" id="path-rating" opacity="0.5" d="M70.1112 87.1209C69.936 86.2377 62.6725 73.3591 58.1066 73.1084C53.5383 72.8554 47.744 86.5467 47.9921 87.0868C48.2451 87.6246 50.2884 86.8046 50.2884 86.8046C50.2884 86.8046 55.7713 75.5854 59.0261 75.5051C62.2784 75.4272 67.0948 86.8289 67.0948 86.8289" fill="#04826A" />
        <path className="pointer" id="path-rating" opacity="0.5" d="M38.9492 23.8156C41.7418 28.4337 53.0579 43.9864 59.5139 44.3246C65.892 44.6603 74.1821 29.4435 77.7847 24.4191C78.3199 23.6745 75.0481 22.2098 74.3621 23.3533C71.3701 28.3145 63.0022 41.5678 58.7598 41.6676C53.5372 41.7868 43.0894 22.2949 43.0894 22.2949" fill="#04826A" />
        <path className="pointer" id="path-rating" opacity="0.5" d="M47.9414 19.6865C49.7585 23.4506 55.5285 33.6333 59.3841 33.8669C62.9623 34.0834 67.2996 24.8253 68.888 20.5211C69.3259 19.3337 66.9785 19.2267 66.5892 20.0515C65.0616 23.2681 61.0577 31.1417 58.4694 31.2123C55.2147 31.2999 50.4031 18.6816 50.4031 18.6816" fill="#04826A" />
    </svg>
}

export default Rating;